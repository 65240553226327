<template>
    <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-break-point="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    class="bg-gradient-blue"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-avatar class="mr-0">
          <img src="@/assets/images/logo-icon.png" />
        </v-list-item-avatar>
      </v-list-item>
      <!---USer Area -->
      <!---Sidebar Items -->
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        :active-class="`success white--text`"
        link
        class="rounded-left"
      >
        <v-list-item-icon>
          <img :src="item.to === $route.path ? require('@/assets/images/' + item.imageActive) : require('@/assets/images/' + item.image)" />
        </v-list-item-icon>

      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [
      {
        title: "Dashboard",
        image: "Log_Sheet.png",
        imageActive: "Log_Sheet_blue.png",
        to: "/dashboard/home"
      },

//      {
//        title: "COVID-19 Status",
//        image: "Virus.png",
//        imageActive: "Virus_blue.png",
//        to: "/dashboard/Covid19-Status/status"
//      },

      // {
      //   title: "Persons",
      //   image: "Persons.png",
      //   imageActive: "Persons_blue.png",
      //   to: "/dashboard/Persons/persons"
      // },

      // {
      //   title: "Receivers",
      //   image: "Receiver.png",
      //   imageActive: "Receiver_blue.png",
      //   to: "/dashboard/Receivers/receivers"
      // },
      // {
      //   title: "Tags",
      //   image: "tag.png",
      //   imageActive: "tag_blue.png",
      //   to: "/dashboard/Tags/tags"
      // },
      // {
      //   title: "Location",
      //   image: "Location.png",
      //   imageActive: "Location_blue.png",
      //   to: "/dashboard/Location/location"
      // },
      {
        title: "Help Center",
        image: "Help_Center.png",
        imageActive: "Help_center_blue.png",
        to: "/dashboard/Help-Center/HelpCenter"
      },
      {
        title: "Wifi Credential",
        image: "wifi.png",
        imageActive: "wifi_blue.png",
        to: "/dashboard/Wifi/wifi"
      }
    ],
    mini: true,
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    }
  },

  methods: {
    
  }
};
</script>
<style lang="scss">
#main-sidebar{
  box-shadow:1px 0 20px rgba(0,0,0,.08);
  -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
  .v-navigation-drawer__border{
    display: none;
  }
  .v-list{
    padding: 8px 15px;
  }
  .v-list-item{
      &__icon--text,
      &__icon:first-child{
        justify-content: center;
        text-align: center;
        width: 20px;
        
      }
      
  }    
}
</style>
